import React from 'react';
import './style/styles.css';
import { Link } from 'react-router-dom';

const LandingPage = () => {
  return (
    <div className="landing-page">
      <img src="https://www.ademsamanli.com/wp-content/uploads/riva-dugun-fotografcisi-cekimleri-8-1197x1600.jpg" alt="Wedding" />
      <h1>Hoş Geldiniz!</h1>
      <p>{process.env.REACT_APP_LANDING_TEXT}</p>
      <div className="d-flex justify-content-center mt-4">
        <Link to="/upload" className="btn btn-primary mx-2 upload-button">
          Anı Yükle
        </Link>
        <Link to="/home" className="btn btn-secondary mx-2 upload-button">
          Tüm Anıları Göster
        </Link>
      </div>
    </div>
  );
};

export default LandingPage;
